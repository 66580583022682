import React from 'react';
import { Link } from "gatsby"

function Footer() {
    return (
        <div>

            <div className="footer-container tw-flex tw-flex-col sm:tw-flex-row lg:tw-flex-row lg:tw-justify-between tw-mx-8 tw-bottom-0 tw-overflow-hidden">

                {/* <img src={Logo} height="20px" className='logo-img tw-mr-8 tw-mb-4 sm:tw-mb-0' /> */}
                <div className="first_div_footer">
                    <span className="logo footer-logo tw-mr-8">
                        <Link to="/">Wipiway</Link>
                    </span>
                    <address className="tw-flex tw-text-base tw-mr-8 tw-flex-1 tw-mb-4 sm:tw-mb-0 tw-mt-4">
                        100 Pine St <br />
                        STE 1250 <br />
                        San Francisco, CA - 94111 <br />
                        USA
                    </address>
                </div>

                <div className="secont_footer_div tw-flex tw-flex-col md:tw-flex-row lg:tw-flex-row tw-w-full tw-max-w-2xl lg:tw-justify-between lg:tw-pl-32 tw-mt-4">
                    <div className="connectuswrapper tw-mb-10 ">

                        <h3 className='f_s_headers tw-mt-4'>Connect With Us</h3>

                        <div className="w-32 tw-h-[2px] tw-my-4 tw-bg-slate-900"></div>

                        <div className="tw-flex tw-mr-8 tw-flex-1 tw-mb-4 sm:tw-mb-0 tw-text-base tw-mt-4">
                            hello@wipiway.com
                        </div>

                        <div className="tw-flex tw-mr-8 tw-flex-1 tw-mb-4 sm:tw-mb-0 tw-text-base tw-mt-4">
                            +1 717 683 9393
                        </div>
                    </div>

                    <div className="socialswrapper  tw-mb-10 ">

                        <h3 className='f_s_headers  tw-mt-4'>Our Socials</h3>
                        <div className="w-32 tw-h-[2px] tw-my-4 tw-bg-slate-900"></div>

                        <ul className='tw-flex-1 tw-mb-4 sm:tw-mb-0 tw-text-base tw-mt-4'>
                            <li>
                                <a href="https://twitter.com/wipiway" target="_blank">
                                    Twitter
                                </a>
                            </li>
                            <li>
                                <a href="https://www.upwork.com/freelancers/niranjans" target="_blank">
                                    Upwork
                                </a>
                            </li>
                            <li>
                                <a href="https://www.linkedin.com/company/wipiway/" target="_blank">
                                    Linkedin
                                </a>
                            </li>
                        </ul>

                    </div>
                </div>





            </div>

            <div className='tw-flex tw-flex-row tw-justify-center tw-align-middle tw-text-center '>
                &copy;  Wipiway, {new Date().getFullYear()}. All Rights Reserved.
            </div>
        </div>
    );
}

export default Footer;
